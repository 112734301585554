import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { LocationsContext } from '@contexts/LocationsContext';
import SectionLink from '../SectionLink/SectionLink';
import HomeSvg from 'pubweb-smokey/dist/images/svg/iconography-16x16/home.svg';
import LocationLineSvg from 'pubweb-smokey/dist/images/svg/iconography-16x16/location-line.svg';
import CopySvg from 'pubweb-smokey/dist/images/svg/iconography-16x16/copy.svg';
import ChevronUpSvg from './images/chevron-up.svg';
import { MobileNavBarStyles } from './MobileNavBar.styled';
import { pushGTMEvent } from 'pubweb-smokey/dist/utils/analytics';
import LivePersonMessage from '../LivePersonMessage/LivePersonMessage';
import { LIVEPERSON_ENGAGEMENT } from '@utils/config';

const MobileNavBar = ({ sticky, livePersonVisible }) => {
  const [isOpen, setIsOpen] = useState(sticky);
  const [showLivePerson, setShowLivePerson] = useState(livePersonVisible);

  useEffect(() => {
    setIsOpen(sticky);
  }, [sticky]);

  useEffect(() => {
    setShowLivePerson(livePersonVisible);
  }, [livePersonVisible]);

  const handleLivePersonMessageClick = () => {
    if (showLivePerson) {
      window.lpTag = window.lpTag || {};
      if (window?.lpTag?.taglets?.rendererStub) {
        window.lpTag.taglets.rendererStub?.click(LIVEPERSON_ENGAGEMENT?.id);
      }
    }
  };

  return isOpen ? (
    <MobileNavBarStyles className={isOpen ? 'sticky' : ''}>
      {showLivePerson ? (
        <div className="nav-msg-us">
          <LivePersonMessage
            handleLivePersonMessageClick={() => {
              handleLivePersonMessageClick();
              pushGTMEvent('ch.livePersonChatbotClick', 'liveperson_open_nav', {
                location: 'Homepage',
                category: 'chat_bot',
                action: 'button',
                label: 'ch.livePersonChatbotClick',
              });
            }}
          ></LivePersonMessage>
        </div>
      ) : null}
      <ul className="nav-list">
        <li
          className="nav-list-item"
          onClick={() =>
            pushGTMEvent('ch.mobileMenuOpenClick', 'mobile_menu_open', {
              location: 'All Pages',
              category: 'home_consideration',
              action: 'navigation',
              label: 'ch.mobileMenuOpenClick',
            })
          }
        >
          <SectionLink to="/find-a-home/">
            <>
              <div className="icon">
                <div>
                  <HomeSvg viewBox="0 0 16 16" />
                </div>
              </div>
              Homes
            </>
          </SectionLink>
        </li>
        <li className="nav-list-item">
          <LocationsContext.Consumer>
            {({ state }) => {
              return (
                <>
                  <SectionLink
                    to={
                      state.postalCode !== null
                        ? `/locations/?postalCode=${state.postalCode}&distance=${state.distance}`
                        : '/locations'
                    }
                    suppressHydrationWarning={true}
                  >
                    <>
                      <div className="icon">
                        <div>
                          <LocationLineSvg viewBox="0 0 16 16" />
                        </div>
                      </div>
                      Locations
                    </>
                  </SectionLink>
                </>
              );
            }}
          </LocationsContext.Consumer>
        </li>
        <li className="nav-list-item">
          <SectionLink to="/studio/" internalLink={true}>
            <>
              <div className="icon">
                <div>
                  <CopySvg viewBox="0 0 16 16" />
                </div>
              </div>
              Studio
            </>
          </SectionLink>
        </li>
      </ul>
    </MobileNavBarStyles>
  ) : (
    <MobileNavBarStyles>
      <button
        className="menu-button"
        onClick={() => {
          setIsOpen(true);
          pushGTMEvent('ch.mobileNavCTAClick', 'mobile_nav_cta', {
            location: 'All Pages',
            category: 'home_consideration',
            action: 'button',
            label: 'ch.mobileNavCTAClick',
          });
        }}
      >
        <div className="icon">
          <div>
            <ChevronUpSvg viewBox="0 0 25 14" />
          </div>
        </div>
      </button>
    </MobileNavBarStyles>
  );
};

export default MobileNavBar;

MobileNavBar.propTypes = {
  sticky: PropTypes.bool,
  livePersonVisible: PropTypes.bool,
};
