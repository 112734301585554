import React from 'react';
import PropTypes from 'prop-types';
import Colors from 'pubweb-smokey/dist/colors';
import ClaytonLogoStyles from './ClaytonLogo.styled.js';

const ClaytonLogo = ({ letterColor, rooftopColor }) => {
  return (
    <ClaytonLogoStyles
      $letterColor={
        letterColor ? letterColor : Colors.primary.claytonBlue.standard
      }
      $rooftopColor={
        rooftopColor ? rooftopColor : Colors.accent.claytonGreen.standard
      }
      xmlns="http://www.w3.org/2000/svg"
      width="91"
      height="39"
      viewBox="0 0 91 39"
    >
      <title>Clayton Homes</title>
      <g fill="none" fillRule="evenodd">
        <path
          className="letter"
          d="M87.844 30.413c.164 0 .312-.016.312-.207 0-.16-.16-.177-.295-.177h-.265v.384h.248zm-.248.747h-.22v-1.316h.51c.323 0 .489.112.489.378 0 .24-.148.339-.349.36l.378.578h-.251l-.348-.562h-.209v.562zm.242-1.662c-.526 0-.935.403-.935.983 0 .538.356.98.935.98.52 0 .927-.4.927-.98s-.407-.983-.927-.983zm0 2.169c-.699 0-1.197-.51-1.197-1.186 0-.717.553-1.19 1.197-1.19.635 0 1.188.473 1.188 1.19 0 .713-.553 1.186-1.188 1.186zM19.077 19.557c-.127-4.39-4.408-6.89-8.358-6.89-5.537 0-9.13 4.21-9.13 9.498 0 5.287 3.593 9.502 9.13 9.502 4.643 0 8.101-2.76 8.358-7.459h-4.768c-.178 2.09-1.459 3.373-3.69 3.373-3.08 0-4.259-2.71-4.259-5.416 0-2.706 1.178-5.414 4.258-5.414 1.998 0 3.435.97 3.691 2.806h4.768zM19.872 30.875L24.641 30.875 24.641 12.667 19.872 12.667zM28.987 27.406c0-1.061.812-1.478 1.784-1.71.948-.26 2.071-.337 2.68-.724.105 2.78-.582 3.966-2.653 3.966-.973 0-1.811-.438-1.811-1.532zm.25-5.447c.207-1.142.973-1.69 2.172-1.69.899 0 2.095.397 2.095 1.402 0 .831-.406 1.092-1.152 1.25-3.01.621-7.711.282-7.711 4.669 0 2.778 1.997 4.077 4.523 4.077 1.578 0 3.241-.47 4.366-1.663.053.437.102.857.258 1.272h4.366c-.509-1.067-.509-2.31-.509-3.453v-5.891c0-3.81-3.041-4.515-6.184-4.515-1.505 0-3.041.262-4.214.939-1.172.697-1.993 1.838-2.066 3.603h4.056z"
          transform="translate(0 .5)"
        />
        <path
          className="letter"
          d="M51.667 18.208h-4.511l-2.54 8.498h-.054l-2.535-8.498h-4.668l3.473 9.078c.258.702 1.114 2.71 1.114 3.413 0 .905-.751 1.459-1.758 1.459h-1.53v3.467h2.068c2.983 0 4.566-.355 5.627-3.215l5.314-14.202z"
          transform="translate(0 .5)"
        />
        <path
          className="letter"
          d="M58.82 18.262h-2.78V14.25h-4.488v4.012h-2.27v2.82h2.27v5.905c0 3.608.976 4.68 4.809 4.68.82 0 1.637-.104 2.46-.128v-3.2c-.397 0-.798.052-1.163.052-.98 0-1.618-.227-1.618-1.276v-6.033h2.78v-2.82z"
          transform="translate(0 .5)"
        />
        <path
          className="letter"
          d="M62.474 24.555c0-1.717.626-3.762 2.695-3.762 2.063 0 2.72 2.045 2.72 3.762 0 1.715-.657 3.737-2.72 3.737-2.07 0-2.695-2.022-2.695-3.737m-4.448 0c0 4.048 2.933 7.112 7.143 7.112 4.209 0 7.164-3.064 7.164-7.112 0-4.049-2.955-7.138-7.164-7.138-4.21 0-7.143 3.09-7.143 7.138M72.333 30.875h4.444v-7.133c0-1.435.733-2.793 2.407-2.793 2.431 0 2.221 1.813 2.221 3.602v6.324h4.441V22.48c0-1.882-.364-5.063-4.887-5.063-1.62 0-3.449.832-4.26 2.17h-.05v-1.794h-4.316v13.082z"
          transform="translate(0 .5)"
        />
        <path
          className="roof"
          d="M31 12.631L54.052 3.958 77.103 12.631 77.103 14.25 54.052 9.484 31 14.25z"
          transform="translate(0 .5)"
        />
      </g>
    </ClaytonLogoStyles>
  );
};

ClaytonLogo.propTypes = {
  letterColor: PropTypes.any,
  rooftopColor: PropTypes.any,
};

export default ClaytonLogo;
