import PropTypes from 'prop-types';
import React from 'react';
import Link from 'next/link';
import Button from '@components/Shared/Buttons/Button';

import { MobileCTAStyles } from './MobileCTA.styled';
import { useRouter } from 'next/router';

const MobileCTA = ({
  internalLink = true,
  addSiteUrl = false,
  siteUrlOverride = '',
  hideDomainSpecificActions = false,
}) => {
  const handleReloadPage = () => {
    window.location.reload();
  };

  // Added hideDomainSpecificActions here because the go.claytonhomes.landingpages use the header on a 404 page.
  // The landing pages are Gatsby and the 404 page was blowing up because it didn't like useLocation (location was null, at least running localhost).
  // The landing pages seemed to be the only thing that used hideDomainSpecificActions bool. Also, it seemed reasonable to
  // use it to suppress the special "find-land" test here since find land is only on the main chcom site.
  const isOnFindLandPage = () => {
    if (hideDomainSpecificActions) {
      return false;
    } else {
      return useRouter().pathname === '/find-land/'; // eslint-disable-line react-hooks/rules-of-hooks
    }
  };

  return (
    <MobileCTAStyles>
      <div className="cta-text">
        <p className="h6">Find the perfect property for your dream home.</p>

        {isOnFindLandPage() ? (
          <Button
            className="gtm-mobile-ad"
            isCompact={true}
            onButtonClick={handleReloadPage}
          >
            View
          </Button>
        ) : (
          <Button
            className="gtm-mobile-ad"
            isCompact={true}
            nextJSUrl={
              addSiteUrl ? `${siteUrlOverride}/find-land/` : `/find-land/`
            }
            linkComponent={Link}
            forceExternalLink={!internalLink}
            target="_self"
          >
            View
          </Button>
        )}
      </div>
      <img src="/public-assets/images/land-it-promo-2.png" alt="Mobile Image" />
    </MobileCTAStyles>
  );
};

MobileCTA.propTypes = {
  addSiteUrl: PropTypes.bool,
  hideDomainSpecificActions: PropTypes.bool,
  internalLink: PropTypes.bool,
  siteUrlOverride: PropTypes.string,
};

export default MobileCTA;
