import xHttp from '@utils/xHttp';
import { getBaseAwsApiUrl, getLocationsApiVersion } from '@utils/config';

export const getLocationByIpOrPostalCode = (postalCode = '') => {
  let url = `${getBaseAwsApiUrl()}${getLocationsApiVersion()}/location/geocode`;

  if (postalCode !== '') {
    url += `/${postalCode}`;
  }

  return xHttp.get(url);
};

export const getLocationsFromSearch = (searchTerm) => {
  if (!searchTerm || searchTerm.length < 3) {
    throw new Error(
      'You cannot search with a searchTerm less than 3 characters.'
    );
  }
  const url = `${getBaseAwsApiUrl()}${getLocationsApiVersion()}/location/geocode/autocomplete/${searchTerm}`;

  return xHttp.get(url);
};
