import { sanitizeUrl } from 'pubweb-smokey/dist/utils/utils';

const prefix = '/newsroom/';

export const prefixRoute = (route) => {
  let routePath;

  if (route.path) {
    routePath = route.path.endsWith('/') ? route.path : route.path + '/';
    return { ...route, path: sanitizeUrl(prefix + routePath) };
  } else if (typeof route === 'string') {
    routePath = route.endsWith('/') ? route : route + '/';
    return sanitizeUrl(prefix + routePath);
  }

  return route;
};
