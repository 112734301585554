import styled from 'styled-components';
import {
  tablet_breakpoint,
  desktop_breakpoint,
} from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';
import Colors from 'pubweb-smokey/dist/colors';

export const MobileCTAStyles = styled.div`
  background-color: ${Colors.primary.claytonBlue.light};
  display: flex;
  height: 104px;
  position: relative;

  img {
    align-self: center;
  }

  p.h6 {
    color: ${Colors.primary.claytonBlue.standard};
    font-size: 12px;
    padding-bottom: 10px;
  }

  @media only screen and (min-width: ${tablet_breakpoint}px) {
    max-width: 326px;
  }

  @media only screen and (min-width: ${desktop_breakpoint}px) {
    display: none;
  }

  .cta-text {
    padding: 14px 0 0 16px;

    a {
      font-size: 12px;
      padding: 7px 12px;
      margin-left: 3px;
    }
  }
`;
