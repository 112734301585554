import { getAuthenticationToken } from '@services/tokenService';
import { LAMBDA_TOKEN } from './config';

const xHttp = {
  get: (url) => getFetchPromise({ url, method: 'GET' }),
  post: (url, data) => getFetchPromise({ url, method: 'POST', data }),
  put: (url, data) => getFetchPromise({ url, method: 'PUT', data }),
  delete: (url) => getFetchPromise({ url, method: 'DELETE' }),
};

const getFetchPromise = ({ url, method, data }) => {
  let bearerToken = getAuthenticationToken();
  bearerToken =
    bearerToken && bearerToken.data ? bearerToken.data : bearerToken;

  const controller = new AbortController();
  const options = {
    method,
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': '*',
      'lambda-token': LAMBDA_TOKEN,
      'Api-Key': 'f05fcbff-bbb8-41b7-95c3-07e0ef305393',
      ApiKey: '4a7838a0-8698-4088-856a-0f327d9f78c4',
      ...(bearerToken && {
        Authorization: 'Bearer ' + bearerToken.access_token,
      }),
    },
    ...(data && { body: JSON.stringify(data) }),
    signal: controller.signal,
  };

  const fetchPromise = fetch(sanitizeUrl(url), options)
    .then(async (res) => {
      const text = await res.text();
      const body = text === '' ? {} : JSON.parse(text);

      if (!res.ok) {
        const error = new Error(
          `Something went wrong! Status code: ${res.status}, response body: ${text}`
        );
        error.response = {
          status: res.status,
          body,
        };
        throw error;
      }

      return body;
    })
    .catch((error) => {
      if (error.name !== 'AbortError') {
        throw error;
      }
    });
  fetchPromise.cancel = () => controller.abort();

  return fetchPromise;
};

const sanitizeUrl = (url) => {
  var cleanedSlashes = url
    .replace(new RegExp('//', 'g'), '/')
    .replace(':/', '://');
  return cleanedSlashes.replace(new RegExp('\\?'), '?');
};

export default xHttp;
