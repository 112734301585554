import styled from 'styled-components';
import chZindex from '../../zIndex';
import { setBelow } from 'pubweb-smokey/dist/components/zIndex';

const localZindex = {
  // The nav bar needs to be under the mobile dropdown menu items when they are open.
  mobileNavBar: setBelow(chZindex.scrollToTopButton),
};

export const MobileNavBarStyles = styled.div`
  background-color: white;
  border-top: 1px solid rgb(228, 229, 231);
  min-height: 37px;
  text-align: center;
  z-index: ${localZindex.mobileNavBar};

  .nav-list {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(3, 1fr);
    list-style: none;
    padding: 12px;
    width: 100%;
  }

  .nav-list-item {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 1.5;
    text-align: center;

    a {
      color: #0c1e34;
      display: block;
      text-decoration: none;
    }
  }

  .icon {
    svg {
      width: 25px;
      height: 25px;
    }

    & > div {
      margin-bottom: -6px;
    }
  }

  .menu-button {
    background: none;
    border: none;
    margin-top: 6px;
    width: 40px;
  }

  &.sticky {
    bottom: 0;
    position: sticky;
  }

  .nav-msg-us {
    > button {
      border-radius: 0;
      width: 100%;
      justify-content: center;
    }
  }
`;
