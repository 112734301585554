import { createGlobalStyle } from 'styled-components';
import Colors from 'pubweb-smokey/dist/colors';

import {
  mobile_width_percentage,
  tablet_breakpoint,
  tablet_container_maxwidth,
  desktop_breakpoint,
  desktop_container_maxwidth,
  desktop_container_maxwidth_compact,
} from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';
import chZindex from '@components/zIndex';

const GlobalStyle = createGlobalStyle`
/* put styles that are sitewide here.
they should be few and far between. */
* {
    box-sizing: border-box;
    font-family: 'source-sans-pro', Helvetica, Arial, sans-serif;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
}
a:link,
a:visited {
    color: ${Colors.primary.claytonBlue.standard};
    font-family: 'source-sans-pro', Helvetica, Arial, sans-serif;
}
a:hover,
a:active {
    text-decoration: none;
}
.scroll-up-button {
    outline: none;
    text-align: center;
    z-index: ${chZindex.scrollToTopButton};

    svg {
        height: 20px;
        width: 20px;
        margin: 15px auto;

        path {
            fill: #fff;
        }
    }
}

@media screen and (min-width: 1051px) {
    .ccpa div {
        margin-bottom: 1rem;
    }
    .ccpa div a {
        float: left;
        margin-right: 20px;
    }
    .ccpa button {
        float: right;
        margin-top: 0;
    }
}

@media screen and (max-width: 1050px) {
    .ccpa button {
        float: left;
    }
}

.ccpa div a,
.ccpa button,
.ccpa .ccpa__body {
    font-family: sans-serif;
}

.ccpa div a {
    text-decoration: underline;
}

.ccpa button {
    color: #fff;
    width: 78px;
    height: 50px;
    border-radius: 2px;
    background-color: #0077cf;
    margin-top: 20px;
}

.ccpa button:hover {
    background-color: #0077cf;
    cursor: pointer;
  }

/* constrained-width keeps things boxed in while still 
    letting a background image or color go all the way across.
    This is used throughout the site. If you see this CSS repeated within pages, 
    it should be safe to remove it from specific pages.
    I have put it here to avoid repeating, just didn't think of it earlier in development!
     */
.constrained-width {
    margin: 0 auto;
    width: ${mobile_width_percentage}%;
}
@media only screen and (min-width: ${tablet_breakpoint}px){
    .constrained-width {
        max-width: ${tablet_container_maxwidth}px;
    }
}
@media only screen and (min-width: ${desktop_breakpoint}px){
    .constrained-width {
        max-width: ${desktop_container_maxwidth}px;
    }
}

.card-image-area .carousel.carousel-slider .control-arrow {
    z-index: ${chZindex.sliderControls};
}

.LPMcontainer.LPMoverlay, .LPMlabel {
    z-index: ${chZindex.livePerson} !important;
}

/* hide the standard right-side Live Person button */
/* .LPMcontainer {
    display: none !important;
} */

/* Adding in Newsroom specific constrained-width rules
since they vary from CHCom's standard rules. Overwriting
from the page/component level is not overwriting base rules*/
.constrained-width-newsroom {
    max-width: ${mobile_width_percentage}%;
    margin: auto;
    padding: 25px;
}
@media only screen and (min-width: ${tablet_breakpoint}px){
    .constrained-width-newsroom {
        max-width: ${tablet_container_maxwidth}px;
    }
}
@media only screen and (min-width: ${desktop_breakpoint}px){
    .constrained-width-newsroom {
        max-width: ${desktop_container_maxwidth_compact}px;
    }
}

/* Osano styles (e.g. hide cookie widget) */
.osano-cm-widget {
    display: none;
}
.osano-cm-dialog {
    display: none;
}

`;

export default GlobalStyle;
