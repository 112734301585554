import React, { useState } from 'react';
import dynamic from 'next/dynamic';
import ChevronDown from 'pubweb-smokey/dist/images/svg/iconography-16x16/chevron-dwn.svg';
import LocationPin from 'pubweb-smokey/dist/images/svg/iconography-16x16/location-line.svg';

import { LocationsContext } from '@contexts/LocationsContext';
import SectionLink from '../../SectionLink/SectionLink';

import { HeaderLocationIndicatorStyles } from './HeaderLocationIndicator.styled';

const LocationSelector = dynamic(
  () => import('@components/Shared/LocationSelector/LocationSelector'),
  { ssr: false }
);

const HeaderLocationIndicator = () => {
  const [showLocationSelector, setShowLocationSelector] = useState(false);

  return (
    <HeaderLocationIndicatorStyles>
      <LocationsContext.Consumer>
        {({ state, actions }) => {
          return (
            <>
              <SectionLink
                tabIndex="0"
                className="location-indicator"
                id="header-location-input"
                to="#"
                internalLink={false}
                onClick={(e) => {
                  e.preventDefault();
                  setShowLocationSelector(!showLocationSelector);
                }}
              >
                <>
                  <p className="shopping-near-desktop-only">Shopping Near</p>
                  <LocationPin
                    alt="Location Pin"
                    className="location-pin-icon-mobile-only"
                    width="16"
                    height="16"
                  />

                  <div
                    className="caption selected-location"
                    suppressHydrationWarning={true}
                  >
                    {state.postalCode}
                  </div>

                  <ChevronDown
                    alt="Change Location"
                    className="location-chevron"
                    width="16"
                    height="20"
                  />
                </>
              </SectionLink>

              {showLocationSelector && (
                <LocationSelector
                  locationKey="header"
                  applyButtonClassName="gtm-header-zip-submit"
                  postalCode={state.postalCode}
                  distance={state.distance}
                  onUpdate={actions.updateLocation}
                  setUpdating={actions.setUpdating}
                  onCloseClick={() => setShowLocationSelector(false)}
                />
              )}
            </>
          );
        }}
      </LocationsContext.Consumer>
    </HeaderLocationIndicatorStyles>
  );
};

export default HeaderLocationIndicator;
