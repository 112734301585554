import styled from 'styled-components';
import { desktop_breakpoint } from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';
import Colors from 'pubweb-smokey/dist/colors';

export const AlertStyles = styled.div`
  background-color: ${(props) =>
    props.bannerColor
      ? props.bannerColor
      : Colors.primary.claytonBlue.standard};

  .close-banner {
    filter: invert(1);
    position: absolute;
    right: 10px;
    top: 8px;
    cursor: pointer;
    max-width: 25px;
  }
  .banner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 40px;
    position: relative;
  }
  .banner-items {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: 5px 30px;
  }

  .banner-callout {
    /* Note: (1) #55408d is not a standard smokey color (2) The rgba color is smokey standard black #25292d, but it needed .25 opacity applied */
    background-color: ${(props) =>
      props.calloutColor ? props.calloutColor : 'rgba(37,41,45,0.25)'};
    height: 22px;
    border-radius: 11px;
    padding: 4px 11px;
  }
  .banner-text {
    /* for the markdown */
    p,
    a {
      color: ${Colors.primary.white.standard};
      font-size: 12px;
      line-height: 16px;
    }
  }
  .banner-text,
  .banner-link {
    font-size: 12px;
    line-height: 16px;
  }
  .banner-callout,
  .banner-text,
  .banner-link {
    color: ${Colors.primary.white.standard};
    margin: 3px 6px;
    text-align: center;
  }

  @media only screen and (min-width: ${desktop_breakpoint}px) {
    .banner-text {
      /* for the markdown */
      p,
      a {
        font-size: 14px;
      }
    }
    .banner-text,
    .banner-link {
      font-size: 14px;
    }
  }
`;
