import styled from 'styled-components';
import { desktop_container_maxwidth } from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';
import Colors from 'pubweb-smokey/dist/colors.js';

export const DynamicDisclaimerStyles = styled.section`
  background-color: ${Colors.secondary.navy1.standard};

  .disclaimer-content {
    margin-bottom: -2px;
    padding: 50px 0;

    width: 86%;
    margin: auto;
    max-width: ${desktop_container_maxwidth}px; // constrain when zoomed out

    p,
    li,
    a {
      color: ${Colors.primary.white.standard};
      font-family: 'source-sans-pro';
      font-size: 12px;
      line-height: 1.42;
      margin-bottom: 12px;
    }

    .markdown {
      p,
      li,
      a {
        color: inherit;
      }
    }
  }
`;

export default DynamicDisclaimerStyles;
