import React from 'react';
import PreFooterSection from './PreFooterSection';
import styles from './GlobalWrapper.module.css';

export const GlobalWrapper = () => {
  return (
    <div className={styles.globalPreFooter}>
      <PreFooterSection
        preFooterActions={[
          {
            alignCtaContent: 'Center Align',
            ctaHeader:
              'Building a better tomorrow with sustainable home building practices.',
            firstCtaButtonText: 'LEARN MORE',
            firstCtaButtonUrl:
              'https://www.claytonhomes.com/social-responsibility/',
            buttonAndTextColors:
              'Transparent Button: #00000000 / White Text and Border: #FFFFFF',
            ctaButtonLocation: 'Below Text',
            ctaImage: {
              description: 'Navy blue tree line',
              title: 'Treeline 3.0',
              file: {
                url: '//images.ctfassets.net/x5dih9iwqm78/DYAILMzQoGl6lBLhnZB87/3279736fd9e11545d9ed31866cfbf2eb/Treeline_3.0.png',
              },
            },
            ctaImageLocation: 'Center',
            ctaBackgroundColor: 'White: #FFFFFF',
            ctaSize: 'Full',
          },
        ]}
      />
    </div>
  );
};

export default GlobalWrapper;
