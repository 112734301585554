import React from 'react';
import { LivePersonMessageStyles } from './LivePersonMessage.styled';
import PropTypes from 'prop-types';
import { pushGTMEvent } from 'pubweb-smokey/dist/utils/analytics';
import MenuChatSvg from '../Header/images/menu-chat.svg';

const LivePersonMessage = ({ handleLivePersonMessageClick }) => {
  return (
    <LivePersonMessageStyles
      onClick={() => {
        handleLivePersonMessageClick();
        pushGTMEvent('LPMcontainer');
      }}
    >
      <div>
        <MenuChatSvg />
      </div>
      <div className="button-text">How can we help?</div>
    </LivePersonMessageStyles>
  );
};

LivePersonMessage.propTypes = {
  handleLivePersonMessageClick: PropTypes.func.isRequired,
};

export default LivePersonMessage;
