import PropTypes from 'prop-types';
import React, { createContext, useState, useEffect, useContext } from 'react';
import { SEARCH_DEFAULT_DISTANCE } from '@utils/config';
import { getLocalStorage, setLocalStorage } from '@utils/utils';
import { getLocationByIpOrPostalCode } from '@services/locationService';
import { DXAccountContext } from './DXAccountContext';

const CH_USER_LOCATION = 'chUserLocation';
const CH_USER_LOCATION_DISTANCE = 'chUserLocationDistance';

export const LocationsContext = createContext({
  state: {
    postalCode: null,
    distance: null,
    geocode: null,
  },
  actions: {},
});

const LocationsContextProvider = ({ children }) => {
  //Setting these to null for now, but this may change as we get maxmind. If used in Locations now, Locations will
  //fill a default for you.

  const dxAccountContext = useContext(DXAccountContext);
  const [geocode, setGeocode] = useState(
    typeof getLocalStorage(CH_USER_LOCATION) !== 'undefined'
      ? getLocalStorage(CH_USER_LOCATION)
      : null
  );
  const [postalCode, setPostalCode] = useState(null);
  const [distance, setDistance] = useState(
    typeof getLocalStorage(CH_USER_LOCATION_DISTANCE) !== 'undefined'
      ? getLocalStorage(CH_USER_LOCATION_DISTANCE)
      : SEARCH_DEFAULT_DISTANCE
  );
  const [updating, setUpdating] = useState(false);
  const [isLocationCallCompleted, setIsLocationCallCompleted] = useState(false);

  const getLocationInfo = (postalCode = '') => {
    getLocationByIpOrPostalCode(postalCode)
      .then((result) => {
        setGeocode(result);
        setPostalCode(result.postalCode);
        setLocalStorage(CH_USER_LOCATION, result);
        setUpdating(false);
        setIsLocationCallCompleted(true);
      })
      .catch((error) => {
        console.log(error);
        setPostalCode(null);
        setUpdating(false);
        setIsLocationCallCompleted(true);
      });
  };

  const actions = {
    setUpdating,
    getLocationInfo: (postalCode = '') => {
      getLocationByIpOrPostalCode(postalCode)
        .then((result) => {
          if (result && result.postalCode) {
            setPostalCode(result.postalCode);
          }

          setGeocode(result);
          setLocalStorage(CH_USER_LOCATION, result);
        })
        .catch((error) => {
          console.log(error);
          setGeocode(null);
          setIsLocationCallCompleted(true);
        });
    },
    updateGeocode: (loc) => {
      setGeocode(loc);
      setLocalStorage(CH_USER_LOCATION, loc);
      setPostalCode(loc.postalCode);
    },
    updateLocation: (tempPostalCode, tempDistance) => {
      actions.updatePostalCode(tempPostalCode);
      actions.updateDistance(tempDistance);
    },
    updatePostalCode: (tempPostalCode) => {
      if (tempPostalCode) {
        setPostalCode(tempPostalCode);
        actions.getLocationInfo(tempPostalCode);
      }
    },
    updateDistance: (tempDistance) => {
      setDistance(tempDistance);
      setLocalStorage(CH_USER_LOCATION_DISTANCE, tempDistance);
    },
    setLocationCallCompleted: (isCompleted) => {
      setIsLocationCallCompleted(isCompleted);
    },
  };

  useEffect(() => {
    setPostalCode(geocode !== null ? geocode.postalCode : null);
  }, [geocode]);

  useEffect(() => {
    //We are ignoring this call on Find A Home since it has zip code info it can share. If it's not there
    //We will make a call manually on find-a-home.
    if (
      postalCode === null &&
      window.location.pathname.indexOf('find-a-home') === -1
    ) {
      getLocationInfo();
    }
  }, []);

  // update user profile on zipcode change if logged in
  useEffect(() => {
    if (
      dxAccountContext.state?.account?.profile?.landZip &&
      dxAccountContext.state?.account?.profile?.landZip !== postalCode
    ) {
      // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
      const { likedFeatures, ...profile } = dxAccountContext.state.account
        .profile || {
        likedFeatures: [],
        profileId: 0,
      };

      dxAccountContext.actions.updateProfile({
        ...profile,
        profileId: profile?.profileId,
        profileType: 'corp',
        landZip: parseInt(postalCode),
      });
    }
  }, [postalCode, dxAccountContext.state.account]);

  const currentContext = {
    state: {
      postalCode,
      distance,
      geocode,
      updating,
      isLocationCallCompleted,
    },
    actions,
  };

  return (
    <LocationsContext.Provider value={currentContext}>
      {children}
    </LocationsContext.Provider>
  );
};

LocationsContextProvider.propTypes = {
  children: PropTypes.any,
};

export default LocationsContextProvider;
