import PropTypes from 'prop-types';
import React from 'react';
import Link from 'next/link';

import { getSiteUrl } from '@utils/config';

const SectionLink = ({
  internalLink = true,
  addSiteUrl = false,
  siteUrlOverride = '',
  to,
  ssr,
  children,
  ...props
}) => {
  if (internalLink) {
    return (
      <Link href={to} {...props}>
        {children}
      </Link>
    );
  } else {
    let url = to;

    if (siteUrlOverride !== '') {
      url = `${siteUrlOverride}${to}`;
    } else {
      if (addSiteUrl) {
        url = ssr ? `${getSiteUrl('', ssr)}${to}` : `${getSiteUrl()}${to}`;
      }
    }

    return (
      <a href={url} {...props}>
        {children}
      </a>
    );
  }
};

SectionLink.propTypes = {
  addSiteUrl: PropTypes.bool,
  children: PropTypes.any,
  internalLink: PropTypes.bool,
  siteUrlOverride: PropTypes.string,
  to: PropTypes.any,
  ssr: PropTypes.bool,
};

export default SectionLink;
