import React from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import Link from 'next/link';

function NavLink({
  activeClassName = 'active',
  to,
  exact,
  children,
  ...props
}) {
  const { pathname } = useRouter();
  const isActive = exact ? pathname === to : pathname.startsWith(to);

  if (isActive) {
    props.className += ` ${activeClassName}`;
  }

  return (
    <Link href={to} {...props}>
      {children}
    </Link>
  );
}

NavLink.propTypes = {
  activeClassName: PropTypes.string,
  to: PropTypes.string.isRequired,
  exact: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default NavLink;
