import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { getGraphUserURI } from './config';
import fetch from 'cross-fetch';

const localStorage = {};

export const getLocalStorage = (key) => {
  let value;
  if (typeof window === 'undefined') {
    value = localStorage[key];
  } else {
    value = window.localStorage[key];
  }

  try {
    value = JSON.parse(value);
  } catch {} // eslint-disable-line no-empty

  return value;
};

export const setLocalStorage = (key, value) => {
  if (typeof value !== 'string') {
    value = JSON.stringify(value);
  }

  if (typeof window === 'undefined') {
    localStorage[key] = value;
    return;
  }

  return window.localStorage.setItem(key, value);
};

const sessionStorage = {};

export const getSessionStorage = (key) => {
  let value;
  if (typeof window === 'undefined') {
    value = sessionStorage[key];
  } else {
    value = window.sessionStorage[key];
  }

  try {
    value = JSON.parse(value);
  } catch {} // eslint-disable-line no-empty

  return value;
};

export const setSessionStorage = (key, value) => {
  if (typeof value !== 'string') {
    value = JSON.stringify(value);
  }

  if (typeof window === 'undefined') {
    sessionStorage[key] = value;
    return;
  }

  return window.sessionStorage.setItem(key, value);
};

export const getCityStateZipFromLocationString = (locationString) => {
  if (locationString) {
    const locationStringParts = locationString.split('--');
    if (locationStringParts.length > 2) {
      return {
        city: locationStringParts[0],
        state: locationStringParts[1],
        postalCode: locationStringParts[2],
      };
    }
  }

  return null;
};

const hexColorRegex = /#[\da-f]{6,8}/gim;

export const extractColors = (stringValue) => {
  if (!stringValue) {
    return [];
  }
  return stringValue.match(hexColorRegex) || [];
};

export const getCookie = (name) => {
  return document.cookie
    .split(';')
    .map((cookie) => {
      const cookieKvp = cookie.split('=');
      return {
        name: cookieKvp[0].trim(),
        value: cookieKvp[1],
      };
    })
    .filter((cookie) => cookie.name == name)[0];
};

export const getGAClientId = () => {
  try {
    const trackingCookie = getCookie('_ga');
    if (trackingCookie) {
      const trackingCookieSplit = trackingCookie.value.split('.');
      return (
        trackingCookieSplit[trackingCookieSplit.length - 2] +
        '.' +
        trackingCookieSplit[trackingCookieSplit.length - 1]
      );
    }
  } catch (e) {
    return '';
  }
};

export const getKenshooId = () => {
  try {
    const urlParams = new URLSearchParams(window.location.search);
    const kenshooId = urlParams.get('kenshooid');
    return kenshooId;
  } catch (e) {
    return '';
  }
};

export const isSlugInternal = (slug) => {
  slug = (slug || '').toLowerCase();

  if (slug[0] !== '/') {
    slug = '/' + slug;
  }

  return true;
};

const internalUrlVariants = [
  '/',
  'www.claytonhomes.com',
  'claytonhomes.com',
  'http://www.claytonhomes.com',
  'https://www.claytonhomes.com',
  'http://claytonhomes.com',
  'https://claytonhomes.com',
];

export const getUrlTarget = (url) => {
  return isUrlSameDomain(url) ? '_self' : '_blank';
};

export const isUrlSameDomain = (url) => {
  url = (url || '').toLowerCase();

  for (let i = 0; i < internalUrlVariants.length; i++) {
    if (url.indexOf(internalUrlVariants[i]) == 0) {
      return true;
    }
  }

  return false;
};

export const isUrlInternal = (url) => {
  if (isUrlSameDomain(url)) {
    return isSlugInternal(getSlug(url));
  }

  return false;
};

export const getSlug = (slug) => {
  slug = (slug || '').toLowerCase();
  slug = slug.replace('//', ':');

  const slashIndex = slug.indexOf('/');
  return slug.substring(slashIndex);
};

export const scrollToElement = (element, yOffset = 0) => {
  if (element) {
    window.scrollTo({
      top: window.pageYOffset + element.getBoundingClientRect().top + yOffset,
      behavior: 'smooth',
    });
  }
};

// Copied from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/random
export const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
};

/*
When linking to other websites from CTA buttons, append any UTM parameters.
It's not necessary to append parameters from within the same domain name.
For example, when linking to go.claytonhomes.com
*/
export const getLinkWithUTMParameters = (url, params) => {
  if (url === null) {
    return null;
  }
  if (!url) {
    return '';
  }
  if (!params) {
    return url;
  }
  if (isUrlSameDomain(url)) {
    return url;
  }
  if (url.includes('?')) {
    return url + '&' + params;
  } else {
    return url + '?' + params;
  }
};

/*
For a given URL, ensure the protocol is HTTPS.
*/
export const formatHttpsUrl = (url) => {
  try {
    if (url) {
      const urlObj = new URL(url.startsWith('//') ? 'https:' + url : url); //throws an error if poorly formatted.
      urlObj.protocol = 'https:';
      return urlObj.toString();
    } else {
      return url; //for backward compatibility, return the original "falsy" value as passed, which may be null, undefined, etc.
    }
  } catch (error) {
    console.error(error, url);
    return url; //for backward compatibility.
  }
};

/*
Apollo Client is needed every time
a user needs access to the GraphQL Endpoints */
export const apolloClient = new ApolloClient({
  link: createHttpLink({
    uri: getGraphUserURI,
    fetch: fetch,
  }),
  cache: new InMemoryCache(),
  ssrMode: true,
});

export const privacyDates = {
  effectiveDate: 'January 1, 2023',
  lastRevisedDate: 'January 17, 2024',
  lastReviewedDate: 'January 1, 2024',
};

// Inspect links in className>markdown. If linking to another domain, set the target to open in a new window.
export const auditMarkdownLinks = (className) => {
  const cards = document.getElementsByClassName(className);
  Array.from(cards).forEach((card) => {
    const markdownElements = card.getElementsByClassName('markdown');
    Array.from(markdownElements).forEach((markdown) => {
      const linkElements = markdown.getElementsByTagName('a');
      Array.from(linkElements).forEach((link) => {
        if (!isUrlSameDomain(link.href)) {
          link.target = '_blank';
        }
      });
    });
  });
};

export const convertHexToRgbA = (hex, a) => {
  // Convert the first 2 characters to hexadecimal
  let r = parseInt(hex?.substring(1, 3), 16),
    // Convert the middle 2 characters to hexadecimal
    g = parseInt(hex?.substring(3, 5), 16),
    // Convert the last 2 characters to hexadecimal
    b = parseInt(hex?.substring(5, 7), 16);

  // Append them all
  return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + a + ')';
};
