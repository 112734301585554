import styled, { css } from 'styled-components';
import Colors from 'pubweb-smokey/dist/colors';
import { kiddiePoolAda } from '@utils/ctaUtils';

const fontAndIconSize = '14px';

export const buttonColorOptions = [
  Colors.primary.claytonBlue,
  Colors.secondary.kiddiePool,
  Colors.secondary.summerNight,
  Colors.accent.ladyBug,
  Colors.accent.cheesePuff,
  Colors.accent.freshGrass,
  Colors.accent.darkFreshGrass,
  Colors.secondary.rubberDucky,
  Colors.accent.darkBlue,
  Colors.primary.black,
  Colors.primary.white,
  Colors.accent.claytonGreen,
  Colors.accent.barnDoor,
  Colors.secondary.transparent,
  kiddiePoolAda,
];

const buttonStyling = css`
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  font-family: 'source-sans-pro', sans-serif;
  font-size: ${fontAndIconSize};
  font-weight: 600;
  justify-content: space-around;
  letter-spacing: 1px;
  padding: 17px 30px;
  text-transform: uppercase;
  transition: background-color 450ms ease;
  width: auto;
  &.compact {
    font-size: 12px;
    padding: 10px 15px;
  }
  &.fill-container {
    width: 100%;
  }
  &.shadow {
    box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.15);
  }
  ${buttonColorOptions
    .map((color) => {
      return `
            &.${color.className} {

                &, &:visited {
                    background-color: ${color.standard};
                    border-color: transparent;
                    color: ${color.textColor || Colors.primary.white.standard};
                    text-decoration: none;
                }
                
                &:active, &:hover{
                    background-color: ${color.hover};
                    color: ${color.textColor || Colors.primary.white.standard};
                }
                &.outlined {
                    border-color: ${color.standard};
                }
                &.on-dark {
                    background-color: ${Colors.primary.white.standard};
                    color: ${color.standard};

                    &:active, &:hover {
                        background-color: ${color.tint}; 
                    }                
                }
                &.inverse {
                    background-color: transparent; 
                    color: ${color.standard};
        
                    &:active, &:hover {
                        background-color: ${color.tint}; 
                    }
                }
                &.shadow {
                    border-color: transparent;
                }
            }
        `;
    })
    .join('\n')}

  &.disabled {
    background-color: ${Colors.accent.grey5.standard};
    pointer-events: none;

    &:active,
    &:hover {
      background-color: ${Colors.accent.grey5.standard};
    }
  }
  .icon {
    margin-right: 10px;
    /* set the max-height of icons to the same size as the font.
        that way a button with icon next to a button without an icon will be the same height */
    max-height: ${fontAndIconSize};

    svg g {
      fill: ${Colors.primary.white.standard};
    }
  }
`;

const ButtonStyles = styled.button.attrs({ className: 'button' })`
  ${buttonStyling}
`;
export const ExternalLinkButtonStyles = styled.a.attrs({ className: 'link' })`
  ${buttonStyling}

  display: inline-flex;
  margin-bottom: 18px;

  &.fill-container {
    display: flex;
    margin-bottom: 0;
    width: 100%;
  }
`;

export const InternalLinkButtonStyles = styled.a`
  ${buttonStyling}

  display: inline-flex;
  margin-bottom: 18px;

  &.fill-container {
    display: flex;
    margin-bottom: 0;
    width: 100%;
  }
`;

export default ButtonStyles;
