import React from 'react';
import PropTypes from 'prop-types';
import TypographyWrapper from './Typography.styled';

const Typography = ({ children }) => {
  return <TypographyWrapper>{children}</TypographyWrapper>;
};

Typography.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

Typography.defaultProps = {};

export default Typography;
