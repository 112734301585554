import styled from 'styled-components';
import { desktop_breakpoint } from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';

export const HeaderLocationIndicatorStyles = styled.div`
  flex-grow: 1;
  position: relative;

  .bottom-controls {
    margin-top: 10px;
  }

  .location-indicator {
    float: right;
    outline: none;
    height: 50px;
    width: 90px;
    margin: 0 0 0 auto;

    :focus-visible {
      outline: auto;
    }

    img,
    svg,
    p,
    div {
      float: left;
      cursor: pointer;
      margin-top: 18px;
    }
    svg {
      margin-top: 20px;
    }

    .shopping-near-desktop-only {
      display: none;
    }

    .location-pin-icon-mobile-only {
      margin-top: 20px;
    }
  }

  @media only screen and (min-width: ${desktop_breakpoint}px) {
    .location-indicator {
      height: 29px;
      margin: 5px 40px 0 0;
      width: 130px;
      margin-left: auto;

      img,
      p,
      div {
        margin-top: 3px;
      }
      svg {
        margin-top: 5px;
      }

      .shopping-near-desktop-only,
      .caption.selected-location {
        display: block;
        font-size: 10px;
        font-weight: bold;
        letter-spacing: 0.5px;
        margin-top: 5px;
        text-transform: uppercase;
      }
      .shopping-near-desktop-only {
        margin-right: 5px;
      }
      .location-pin-icon-mobile-only {
        display: none;
      }
    }
  }
`;
