import React from 'react';
import PropTypes from 'prop-types';
import { marked } from 'marked';

const Markdown = ({ markdown = '' }) => {
  marked.setOptions({
    pedantic: false,
    gfm: true,
    tables: true,
    breaks: false,
    sanitize: false,
    smartLists: true,
    smartypants: false,
    xhtml: false,
    closeHtmlTags: true,
  });

  return (
    markdown && (
      <div
        className="markdown"
        dangerouslySetInnerHTML={{ __html: marked(markdown.trim()) }}
      />
    )
  );
};

export default Markdown;

Markdown.propTypes = {
  markdown: PropTypes.string.isRequired,
};
