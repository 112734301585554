import React from 'react';
import PropTypes from 'prop-types';
import { LocationsContext } from '@contexts/LocationsContext';

import { UtilityNavStyles } from './UtilityNav.styled';
import SectionLink from '../../SectionLink/SectionLink';

const UtilityNav = ({
  internalLink = true,
  addSiteUrl = false,
  siteUrlOverride = '',
  onLinkClick,
}) => {
  return (
    <UtilityNavStyles>
      <ul className="utility-nav">
        <li>
          <LocationsContext.Consumer>
            {({ state }) => {
              return (
                <>
                  <SectionLink
                    onClick={onLinkClick}
                    to={
                      state.postalCode !== null
                        ? `/locations/?postalCode=${state.postalCode}&distance=${state.distance}`
                        : `/locations`
                    }
                    className="caption"
                    internalLink={internalLink}
                    addSiteUrl={addSiteUrl}
                    siteUrlOverride={siteUrlOverride}
                    suppressHydrationWarning={true}
                  >
                    Locations
                  </SectionLink>
                </>
              );
            }}
          </LocationsContext.Consumer>
        </li>
        <li>
          <SectionLink
            onClick={onLinkClick}
            to="/contact-us/"
            className="caption"
            internalLink={internalLink}
            addSiteUrl={addSiteUrl}
            siteUrlOverride={siteUrlOverride}
          >
            Contact Us
          </SectionLink>
        </li>
      </ul>
    </UtilityNavStyles>
  );
};

UtilityNav.propTypes = {
  addSiteUrl: PropTypes.bool,
  internalLink: PropTypes.bool,
  onLinkClick: PropTypes.func.isRequired,
  siteUrlOverride: PropTypes.string,
};

export default UtilityNav;
