import styled from 'styled-components';
import {
  tablet_breakpoint,
  tablet_container_maxwidth,
  desktop_breakpoint,
  desktop_container_maxwidth,
} from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';
import Colors from 'pubweb-smokey/dist/colors';

import chZindex from '../../zIndex';

export const FooterStyles = styled.div`
  background-color: ${Colors.secondary.navy1.standard};
  position: relative;
  z-index: ${chZindex.footer};

  footer {
    margin: 0 auto;
    padding-top: 40px;
    width: 90%;
  }
  footer,
  .legal-privacy {
    ul {
      list-style-type: none;
      list-style-image: none;
    }
    .menu-hidden-mobile,
    .menu-visible-mobile {
      margin-bottom: 50px;
      margin-top: -20px;

      li {
        line-height: 100%;
        margin-bottom: 8px;
      }
    }
    .menu-hidden-mobile {
      display: none;
    }
    a:link,
    a:visited,
    a:hover,
    a:active {
      color: ${Colors.accent.grey3.standard};
      text-decoration: none;
      transition: color 400ms ease;

      &:hover {
        color: #fff;
      }
    }
    .footer-heading {
      color: ${Colors.accent.grey3.standard};
    }
    .h5 {
      border-bottom: solid 1px ${Colors.accent.navy3.standard};
      color: #fff;
      display: flex;
      justify-content: space-between;
      margin-bottom: 32px;
      padding: 10px 0 10px 0;

      > div.up {
        transform: rotate(180deg);
      }
      svg {
        path {
          fill: #fff;
        }
      }
    }
  }
  .logo-social {
    margin-top: 70px;
    text-align: center;

    .clayton-logo {
      margin-bottom: 30px;

      svg {
        width: 95px;
      }
    }
    .social-icons {
      display: flex;
      margin-bottom: 24px;
      justify-content: space-around;

      svg {
        height: 26px;
        width: 26px;

        path {
          fill: #fff;
        }
      }
    }
  }
  .legal-privacy {
    background-color: ${Colors.accent.navy2.standard};
    text-align: center;
    padding: 10px 0;

    p {
      color: #fff;
    }

    .legal-privacy-links {
      a {
        border-right: solid 1px ${Colors.accent.grey3.standard};
        padding-right: 10px;

        &:nth-child(2) {
          padding-left: 10px;
        }
        &:last-child {
          border-right: none;
          padding-left: 10px;
          padding-right: 0;
        }
      }
    }
  }
  @media only screen and (min-width: ${tablet_breakpoint}px) {
    footer {
      max-width: ${tablet_container_maxwidth}px;
    }
    .social-icons {
      margin: 0 auto;
      max-width: 200px;
    }
  }
  @media only screen and (min-width: ${desktop_breakpoint}px) {
    footer {
      display: flex;
      padding: 20px 0 50px 0;
      max-width: ${desktop_container_maxwidth}px;

      .menu-hidden-mobile,
      .menu-visible-mobile {
        display: block;
        margin-bottom: 0;
        margin-top: 0;
      }
      .h5 {
        margin-bottom: 10px;

        > div {
          display: none;
        }
      }
      > div {
        margin-right: 50px;
        width: 160px;
      }
      .shop {
        order: 2;
      }
      .discover {
        order: 3;
      }
      .connect {
        order: 4;
      }
      .account {
        order: 5;
      }
      .logo-social {
        margin: 0 150px 0 0;
        order: 1;
        padding-top: 10px;
        text-align: left;

        .clayton-logo {
          margin-left: 1px;
        }
        .social-icons {
          margin: 0;
          max-width: none;
        }
      }
    }
    .legal-privacy {
      .legal-privacy-inside {
        display: flex;
        justify-content: center;
        gap: 3rem;
        margin: 0 auto;
        max-width: 600px;
      }
    }
  }
`;
