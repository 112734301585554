import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
/* put styles that are sitewide here.
they should be few and far between. */
* {
    box-sizing: border-box;
    font-family: 'acumin-pro', Helvetica, Arial, sans-serif;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
}

img {
    border: 0;
}

@media screen and (min-width: 1051px) {
    .ccpa div {
        margin-bottom: 1rem;
    }
    .ccpa div a {
        float: left;
        margin-right: 20px;
    }
    .ccpa button {
        float: right;
        margin-top: 0;
    }
}

@media screen and (max-width: 1050px) {
    .ccpa button {
        float: left;
    }
}

.ccpa div a,
.ccpa button,
.ccpa .ccpa__body {
    font-family: sans-serif;
}

.ccpa div a {
    text-decoration: underline;
}

.ccpa button {
    color: #fff;
    width: 78px;
    height: 50px;
    border-radius: 2px;
    background-color: #0077cf;
    margin-top: 20px;
}

.ccpa button:hover {
    background-color: #0077cf;
    cursor: pointer;
  }

.dropdown-display li.selected svg {
    top: 11px;
}

/* hide the standard right-side Live Person button */
/* .LPMcontainer {
    display: none !important;
} */

/* Osano styles (e.g. hide cookie widget) */
.osano-cm-widget {
    display: none;
}
.osano-cm-dialog {
    display: none;
}

`;

export default GlobalStyle;
