import styled from 'styled-components';
import {
  tablet_breakpoint,
  desktop_breakpoint,
} from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';

export const WebsiteBannerStyles = styled.div`
  /* Limit the max space for the banners and add a scroll bar as needed */
  max-height: 300px;
  overflow-y: auto;

  @media only screen and (min-width: ${tablet_breakpoint}px) {
  }
  @media only screen and (min-width: ${desktop_breakpoint}px) {
  }
`;
