import React from 'react';
import DynamicDisclaimerStyles from './DynamicDisclaimer.styled';
import PropTypes from 'prop-types';
import Markdown from '../../Shared/Markdown/Markdown';

const DynamicDisclaimer = ({
  disclaimerContent,
  backgroundColor = '',
  textColor = '',
  ...props
}) => {
  return (
    <DynamicDisclaimerStyles
      style={{
        backgroundColor: backgroundColor,
      }}
      {...props}
    >
      {disclaimerContent && (
        <div
          className="disclaimer-content"
          style={{
            color: textColor,
          }}
        >
          <Markdown markdown={disclaimerContent} />
        </div>
      )}
    </DynamicDisclaimerStyles>
  );
};

export default DynamicDisclaimer;

DynamicDisclaimer.propTypes = {
  disclaimerContent: PropTypes.string,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
};
