import React, { useState } from 'react';
import Link from 'next/link';
import { NewsroomHeaderStyles } from './NewsroomHeader.styled';

import ClaytonLogo from '@components/Shared/ClaytonLogo/ClaytonLogo';
import ClaytonLogoMobile from '@components/Shared/ClaytonLogoMobile/ClaytonLogoMobile';

import HamburgerClose from 'pubweb-smokey/dist/images/svg/iconography-16x16/x.svg';
import Hamburger from 'pubweb-smokey/dist/images/svg/iconography-16x16/hamburger.svg';
import { prefixRoute } from '@utils/newsroomUtils';

if (
  typeof window !== 'undefined' &&
  typeof document !== 'undefined' &&
  !document.getElementById('ccpa_script')
) {
  let source = `https://privacy.claytonhomes.com/ccpa/v5/index.min.js`;
  let script = document.createElement('script');
  script.src = source;
  script.defer = true;
  script.id = 'ccpa_script';
  script.setAttribute('data-class', 'ccpa');
  script.setAttribute('data-id', 'ccpa-banner');
  document.getElementsByTagName('head')[0].appendChild(script);
}

const NewsroomHeader = () => {
  const [navOpen, setNavOpen] = useState(false);

  return (
    <NewsroomHeaderStyles>
      <div className="nav-container-newsroom">
        <div className="nav-main">
          <div className="nav-logo-burger">
            <div className="logo" data-test-id="logo-click">
              <div className="logo-mobile">
                <Link href={'/'} target="_blank">
                  <ClaytonLogoMobile />
                </Link>
              </div>
              <div className="logo-desktop">
                <Link href={'/'} id="clayton-homes">
                  <ClaytonLogo />
                </Link>
              </div>
              <div className="page-title">
                <Link href={prefixRoute('/')} id="newsroom">
                  Newsroom
                </Link>
              </div>
            </div>

            <button
              aria-label="menu"
              data-test-id="menu-toggle"
              className={`nav-burger`}
              onClick={() => setNavOpen(!navOpen)}
            >
              {navOpen ? <HamburgerClose /> : <Hamburger />}
            </button>
          </div>

          <div className={`nav-menu-items ${navOpen ? 'open' : 'closed'}`}>
            <div className="nav-menu-items-inside">
              <ul>
                <li>
                  <Link
                    href={prefixRoute('/press-releases')}
                    id="press-releases"
                    onClick={() => {
                      setNavOpen(false);
                    }}
                  >
                    Press Releases
                  </Link>
                </li>
                <li>
                  <Link
                    href={prefixRoute('/in-the-news')}
                    id="in-the-news"
                    onClick={() => {
                      setNavOpen(false);
                    }}
                  >
                    In the News
                  </Link>
                </li>
                <li>
                  <Link
                    href={prefixRoute('/press-kits')}
                    id="press-kits"
                    onClick={() => {
                      setNavOpen(false);
                    }}
                  >
                    Press Kits
                  </Link>
                </li>
                <li>
                  <Link
                    href={'/about'}
                    id="about"
                    target="_blank"
                    onClick={() => {
                      setNavOpen(false);
                    }}
                  >
                    About Clayton
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </NewsroomHeaderStyles>
  );
};

export default NewsroomHeader;
