import { getLocalStorage, setLocalStorage } from '@utils/utils';

const VISITOR_TOKEN = 'visitorToken';

export const getVisitorToken = () => {
  return getLocalStorage(VISITOR_TOKEN);
};

export const setVisitorToken = (token) => {
  setLocalStorage(VISITOR_TOKEN, token);
};

const BEARER_TOKEN = 'bearerToken';
export const getAuthenticationToken = () => {
  const bearerToken = getLocalStorage(BEARER_TOKEN);
  if (bearerToken) {
    if (new Date() > new Date(bearerToken.expiresAt)) {
      setAuthenticationToken(null);
      return null;
    }
  }

  return bearerToken;
};

export const isTokenExpired = () => {
  const token = getLocalStorage(BEARER_TOKEN);
  //const date = new Date('December 17, 2022 03:24:00');
  if (token) {
    if (new Date() > new Date(token.expiresAt)) {
      return true;
    } else {
      return false;
    }
  }
};

export const setAuthenticationToken = (token) => {
  return setLocalStorage(BEARER_TOKEN, token);
};

export const isValidTokenExists = () => {
  const token = getLocalStorage(BEARER_TOKEN);
  //const date = new Date('December 17, 2022 03:24:00');
  if (token) {
    if (new Date() > new Date(token.expiresAt)) {
      return true;
    } else {
      return false;
    }
  }

  return true;
};
