import React from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import StudioStyles from './Studio.styled';

const SectionTheme = ({ children }) => {
  const router = useRouter();

  return (
    <>
      {router.pathname.includes('/studio') ? (
        <StudioStyles>{children}</StudioStyles>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

SectionTheme.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default SectionTheme;
