// These are utility functions shared by CTA components.
//
// Note:
// Ideally there would be a single CTA component, but there are several CTA components in the code.
// These may all be copies, but that needs to be determined before consolidating them.

import { extractColors, formatHttpsUrl } from '@utils/utils';
import Colors from 'pubweb-smokey/dist/colors';

// A common function used by CTA components.
export const getCtaProperties = (cta) => {
  return {
    buttonLocation: cta.ctaButtonLocation,
    buttonColors: extractColors(cta.buttonAndTextColors),
    secondButtonColors: cta.secondButtonColor,
    backgroundColor: extractColors(cta.ctaBackgroundColor),
    image:
      cta.ctaImage !== null ? formatHttpsUrl(cta?.ctaImage?.file?.url) : null,
    imageAlign: cta.ctaImageLocation,
    contentAlign: cta.alignCtaContent,
    customCategory: cta.ctaCustomCategory,
    tag: cta.ctaTag,
    headingText: cta.ctaHeader,
    subHeadingText: cta.ctaSubHeader,
    ctaText: cta.ctaIntro,
    buttonText: cta.firstCtaButtonText,
    buttonUrl: cta.firstCtaButtonUrl,
    button2Text: cta.secondCtaButtonText,
    button2Url: cta.secondCtaButtonUrl,
    buttonOpensToNewTab: true,
  };
};

export const kiddiePoolAda = {
  standard: '#007e86',
  className: 'kiddie-pool-ada',
};

export const convertButtonColor = (hexCode) => {
  let color;

  // Check these or the toLowerCase will give an exception.
  if (!hexCode || !hexCode[0] || !hexCode[1]) {
    // What to do if no color defined? Could leave color as 'undefined' as well.
    return Colors.primary.claytonBlue;
  }

  // If button color with white text here.
  switch (hexCode[0].toLowerCase() || '#ffffff') {
    case '#0075c9':
      color = Colors.primary.claytonBlue;
      break;
    case '#352269':
      color = Colors.secondary.summerNight;
      break;
    case '#00b0ac':
      color = Colors.secondary.kiddiePool;
      break;
    case '#ef4b5d':
      color = Colors.accent.ladyBug;
      break;
    case '#004b8d':
      color = Colors.accent.darkBlue;
      break;
    case '#b21f28':
      color = Colors.accent.barnDoor;
      break;
    case '#37862c':
      color = Colors.accent.darkFreshGrass;
      break;
    case '#007e86':
      color = kiddiePoolAda;
      break;

    case '#00000000':
      color = Colors.secondary.transparent;
      break;
    default:
      color = Colors.primary.claytonBlue;
  }

  // If white button with text color here.
  if (hexCode[0].toLowerCase() === '#ffffff') {
    switch (hexCode[1].toLowerCase()) {
      case '#0075c9':
        color = Colors.primary.claytonBlue;
        break;
      case '#352269':
        color = Colors.secondary.summerNight;
        break;
      case '#00b0ac':
        color = Colors.secondary.kiddiePool;
        break;
      case '#ef4b5d':
        color = Colors.accent.ladyBug;
        break;
      case '#004b8d':
        color = Colors.accent.darkBlue;
        break;
      case '#b21f28':
        color = Colors.accent.barnDoor;
        break;
      case '#007e86':
        color = kiddiePoolAda;
        break;
      case '#00000000':
        color = Colors.secondary.transparent;
        break;
      default:
        color = Colors.primary.claytonBlue;
    }
  }
  return color;
};

export const determineTextColor = (backgroundColorHexCode) => {
  let color;
  switch (backgroundColorHexCode[0].toLowerCase()) {
    case '#00000000':
      //color = Colors.secondary.transparent; // The shared/CTA used this color in the function.
      color = Colors.primary.white.standard; // The other versions of this function used this color.
      break;
    case '#f8f8f8':
    case '#e5f5ff':
    case '#e2ffc6':
    case '#ffffff':
    case undefined:
      color = Colors.primary.black.standard;
      break;
    default:
      color = '#ffffff';
  }
  return color;
};

export const reverseColors = (hexCode) => {
  //returns true or false
  //if true, reverse colors by passing in {true} to the buttons onDarkBg prop

  // Check these or the toLowerCase will give an exception.
  if (!hexCode || !hexCode[0] || !hexCode[1]) {
    return false;
  }

  //if the button is transparent (#00000000), onDarkBG is always false
  if (hexCode[0] === '#00000000' || hexCode[1] === '#00000000') {
    return false;
  }
  //if color[0] is #ffffff, onDarkBG is true (i.e. white button, with colored text)
  if (hexCode[0].toLowerCase() === '#ffffff') {
    return true;
  }

  return false;
};

export const getImageAlignment = (contentfulString) => {
  let imageAlignment;

  switch (contentfulString) {
    case 'Right Side':
      imageAlignment = 'right';
      break;
    case 'Left Side':
      imageAlignment = 'left';
      break;
    case 'Center':
      imageAlignment = 'center';
      break;
    default:
      imageAlignment = 'right';
  }
  return imageAlignment;
};

export const getContentAlignment = (contentfulString) => {
  let contentAlignment;
  switch (contentfulString) {
    case 'Right Align':
      contentAlignment = 'right';
      break;
    case 'Left Align':
      contentAlignment = 'left';
      break;
    case 'Center Align':
      contentAlignment = 'center';
      break;
    default:
      contentAlignment = 'right';
  }
  return contentAlignment;
};

// adds a custom class to the CTA button based off of the button text so that we have a class on there to target for GTM triggers.
export const getClassName = (buttonText) => {
  let className = buttonText;
  className = className.replace(/\s+/g, '-').toLowerCase() + ' cta-button';

  return className;
};

// This determines the flex alignment for the button.
export const getButtonAlignment = (buttonAlign, contentAlign) => {
  const contentAlignment = contentAlign
    ? getContentAlignment(contentAlign)
    : undefined;
  let buttonAlignment = 'flex-start';

  if (buttonAlign) {
    if (buttonAlign === 'right') {
      buttonAlignment = 'flex-end';
    } else if (contentAlignment) {
      switch (contentAlignment) {
        case 'right':
          buttonAlignment = 'flex-end';
          break;
        case 'center':
          buttonAlignment = 'center';
          break;
        case 'left':
        default:
          buttonAlignment = 'flex-start';
      }
    }
  }
  return buttonAlignment;
};
