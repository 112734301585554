import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Colors from 'pubweb-smokey/dist/colors';

import Alert from './Alert';
import { getWebsiteBanner } from '@services/contentfulService';
import { getLocalStorage, setLocalStorage } from '@utils/utils';
import { CH_ALERTS_CLOSED_KEY, ALERT_DISMISS_EXPIRE_MS } from '@utils/config';

import { WebsiteBannerStyles } from './WebsiteBanner.styled';

const BANNER_COLOR_1 = Colors.primary.claytonBlue.standard;
const BANNER_COLOR_2 = Colors.secondary.summerNight.standard;
const CALLOUT_COLOR_1 = 'rgba(37,41,45,0.25)'; // Note: The rgba color is smokey standard black #25292d, but it needed .25 opacity applied.
const CALLOUT_COLOR_2 = '#55408d'; // Note: #55408d is not a standard smokey color.

// Pass the appropriate key for desired contentful Website Banner (e.g. Ch.com, Careers)
// Also pass a key value to use for local storage tracking dismissed alerts (use different key for ch.com, careers, etc.)
//
// Added internalLink bool to override use of <Link> when WebsiteBanner component is used with a site like Studio.
// Otherwise, you get and error when <Link> is used by Alerts when component is used from Studio (refer to the usage of Header/Footer components in Studio which is similar).
// Added linkPrefix to allow a site like Newsroom to pass a prefix (e.g. /newsroom) that could be added to the link URL for a Contentful sourced alert page.
const WebsiteBanner = ({
  contentfulKey = null,
  localStorageKey = CH_ALERTS_CLOSED_KEY,
  internalLink = true,
  linkPrefix = '',
}) => {
  const [closedAlerts, setClosedAlerts] = useState(
    getLocalStorage(localStorageKey) || []
  );
  const [banner, setBanner] = useState(null);

  const getBannerData = () => {
    if (contentfulKey) {
      getWebsiteBanner(contentfulKey)
        .then((result) => {
          setBanner(result);
          if (result && result.activeAlerts) {
            cleanDismissedAlertStorage(result.activeAlerts);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    getBannerData();
  }, []);

  const handleAlertClose = (alertId) => {
    const index = closedAlerts.findIndex((a) => a.id === alertId);
    const timeExpired =
      index < 0
        ? false
        : Date.now() >
          closedAlerts[index].dismissTime + ALERT_DISMISS_EXPIRE_MS;
    if (index < 0 || timeExpired) {
      let alertsClosed = closedAlerts;
      timeExpired
        ? (alertsClosed[index].dismissTime = Date.now())
        : alertsClosed.push({ id: alertId, dismissTime: Date.now() });
      setClosedAlerts(alertsClosed);
      setLocalStorage(localStorageKey, alertsClosed);
    }
  };

  // This cleans alerts that don't exist in the latest website banner info from the local storage for dimissed alerts (so it won't continue to grow)
  const cleanDismissedAlertStorage = (activeAlerts) => {
    if (closedAlerts && activeAlerts) {
      let alertsClosed = [];
      closedAlerts.map((dismissed) => {
        const index = activeAlerts.findIndex(
          (a) => a.contentfulPageName === dismissed.id
        );
        if (index > -1) {
          alertsClosed.push(dismissed); // return the alert info from dimissed alerts
        }
      });
      setClosedAlerts(alertsClosed);
      setLocalStorage(localStorageKey, alertsClosed);
    }
  };

  let activeAlerts =
    banner && banner.activeAlerts
      ? banner.activeAlerts.map((alert) => {
          const index = closedAlerts.findIndex(
            (a) => a.id === alert.contentfulPageName
          );
          const timeExpired =
            index < 0
              ? false
              : Date.now() >
                closedAlerts[index].dismissTime + ALERT_DISMISS_EXPIRE_MS;
          if (index < 0 || timeExpired) {
            return alert;
          }
        })
      : [];

  return (
    <WebsiteBannerStyles>
      {activeAlerts.map((alert, index) => {
        // Start with color A first and alternate for each alert banner
        return (
          <Alert
            key={`alert-banner-${index}`}
            alert={alert}
            onClose={handleAlertClose}
            bannerColor={index % 2 ? BANNER_COLOR_2 : BANNER_COLOR_1}
            calloutColor={index % 2 ? CALLOUT_COLOR_2 : CALLOUT_COLOR_1}
            internalLink={internalLink}
            linkPrefix={linkPrefix}
          />
        );
      })}
    </WebsiteBannerStyles>
  );
};

WebsiteBanner.propTypes = {
  contentfulKey: PropTypes.string.isRequired,
  internalLink: PropTypes.bool,
  linkPrefix: PropTypes.string,
  localStorageKey: PropTypes.any,
};

export default WebsiteBanner;
