import styled from 'styled-components';
import {
  mobile_width_percentage,
  tablet_container_maxwidth,
  desktop_breakpoint,
  desktop_breakpoint_extended,
  desktop_container_maxwidth_compact,
} from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';
import colors from 'pubweb-smokey/dist/colors';

export const PreFooterSectionStyles = styled.div`
  margin-top: 96px;
  .cta-inside {
    p {
      color: ${colors.primary.white.standard};
    }
  }

  /* Add spacing between text above and button for all views */
  .cta-buttons {
    margin-top: 25px;
  }

  .cta-container {
    margin: 0 auto 30px;

    .cta-position-fullwidth {
      > div {
        margin: 30px auto;
        min-height: 490px;
      }

      /* override some things in the CTA component */
      .cta-heading {
        font-size: 34px;
        line-height: 40px;
      }
      .cta-text {
        font-size: 18px;
      }
      .cta-inside {
        padding: 85px 0 30px 0;
        /* This was done to match constrained-width settings to make CTA text align with edge of cards above it that are in a constrained-width container */
        margin: 0 auto;
        width: ${mobile_width_percentage}%;
      }
      .cta {
        padding: 20px 0;
      }
    }

    .cta-position-inset-single {
      > div {
        margin: 30px auto;
        min-height: 490px;
        border-radius: 4px;
      }
      .cta-heading {
        font-size: 34px;
        line-height: 40px;
      }

      .cta-text {
        font-size: 18px;
      }
      .cta-inside {
        padding: 85px 0 30px 0;
        /* This was done to match constrained-width settings to make CTA text align with edge of cards above it that are in a constrained-width container */
        margin: 0 auto;
        width: ${mobile_width_percentage}%;
      }
      .cta {
        padding: 20px 0;
      }
    }

    .cta-div {
      > div {
        height: 100%;
        min-height: 260px;
        border-radius: 4px;
      }
      /* override some things in the CTA component */
      .custom-category {
        font-size: 10px;
        line-height: 12px;
        /* margin-top: 5px; */
      }
      .cta-inside {
        padding: 5px;
      }
    }
    .cta-three-container,
    .cta-two-container {
      display: grid;
      grid-gap: 40px;
    }
    .cta-grid-container {
      /* override some things in the CTA component */
      .cta-heading {
        font-size: 26px;
        line-height: 31px;
        letter-spacing: -0.35px;
        font-weight: 700;
      }
    }
  }

  @media screen and (min-width: ${desktop_breakpoint}px) {
    .cta-container {
      .constrained-width {
        padding-bottom: 68px;
      }
      .cta-position-fullwidth {
        padding-bottom: 108px;
        > div {
          /* the CTA component sets a max width that needs to be undone */
          max-width: none;
          width: 100%;
        }
        .cta-text {
          max-width: 65%;
        }

        //Desktop sizes only - center all content for a fullwidth CTA.
        .cta,
        .cta-inside {
          display: grid;
          height: 80%;
          align-items: center;

          .cta-buttons {
            margin: 0;
          }
          .cta-heading {
            margin: 0;
          }
          .cta-text {
            margin: 0;
          }
        }
        .cta-inside {
          /* This was done to match constrained-width settings to make CTA text align with edge of cards above it that are in a constrained-width container */
          max-width: ${tablet_container_maxwidth}px;
        }
      }

      .cta-position-inset-single {
        > div {
          /* the CTA component sets a max width that needs to be undone */
          max-width: none;
          width: 100%;
        }
        //Desktop sizes only - center all content for a fullwidth CTA.
        .cta-text {
          max-width: 65%;
        }

        .cta,
        .cta-inside {
          display: grid;
          height: 80%;
          align-items: center;

          .cta-buttons {
            margin: 0;
          }
          .cta-heading {
            margin: 0;
          }
          .cta-text {
            margin: 0;
          }
        }
        .cta-inside {
          /* This was done to match constrained-width settings to make CTA text align with edge of cards above it that are in a constrained-width container */
          max-width: ${tablet_container_maxwidth}px;
        }
      }
    }
  }

  @media screen and (min-width: ${desktop_breakpoint_extended}px) {
    .cta-container {
      .cta-position-fullwidth {
        /* override some things in the CTA component */
        .cta-heading {
          font-size: 40px;
        }
        .cta-inside {
          /* This was done to match constrained-width settings to make CTA text align with edge of cards above it that are in a constrained-width container */
          max-width: ${desktop_container_maxwidth_compact}px;
        }
        .cta-text {
          max-width: 35%;
        }
      }

      .cta-position-inset-single {
        /* override some things in the CTA component */
        .cta-heading {
          font-size: 40px;
        }
        .cta-inside {
          /* This was done to match constrained-width settings to make CTA text align with edge of cards above it that are in a constrained-width container */
          max-width: ${desktop_container_maxwidth_compact}px;
        }
        .cta-text {
          max-width: 35%;
        }
      }

      .cta-div {
        > div {
          height: 100%;
          min-height: 260px;
        }
      }
      .cta-three-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        grid-gap: 20px;
        .left {
          grid-column: 1;
          grid-row: 1 / 3;

          /* override some things in the CTA component */
          .cta-heading {
            max-width: 80%;

            font-size: 32px;
            line-height: 1.19;
            letter-spacing: -0.35px;
            font-weight: 700;
          }
        }
        .right-a {
          grid-column: 2;
          grid-row: 1;
        }
        .right-b {
          grid-column: 2;
          grid-row: 2;
        }
        .right-a,
        .right-b {
          /* override some things in the CTA component */
          .cta-heading {
            font-size: 32px;
            line-height: 38px;
            letter-spacing: -0.35px;
            font-weight: 700;
          }
        }
      }
      .cta-two-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
        .left {
          grid-column: 1;
        }
        .right {
          grid-column: 2;
        }
        .left,
        .right {
          /* override some things in the CTA component */
          .cta-heading {
            font-size: 32px;
            line-height: 38px;
            letter-spacing: -0.35px;
            font-weight: 700;
          }
        }
      }
    }
  }
`;

export default PreFooterSectionStyles;
