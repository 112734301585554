import styled from 'styled-components';

import {
  mobile_width_percentage,
  desktop_breakpoint,
} from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';
import Colors from 'pubweb-smokey/dist/colors';
import zIndices, {
  setBelow,
  setSameAs,
} from 'pubweb-smokey/dist/components/zIndex';

let zIndicies = {
  entireComponent: setBelow(zIndices.dropDownMenuModal), // sets the z-index to below what dropDownMenuModal's
  navMenuItemsDesktop: setSameAs(), // sets the z-index to be the same as previous one
  navLogoBurgerMobile: setSameAs(), // sets the z-index to be the same as previous one
  navMenuItemsMobile: setBelow(), // sets the z-index to be below the previous one
};

const openPositionsButtonWidth = '170';

export const NewsroomHeaderStyles = styled.header`
  background-color: ${Colors.primary.white.standard};
  box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.1);
  width: 100%;
  position: fixed;
  top: 0;
  z-index: ${zIndicies.entireComponent};

  a:link,
  a:visited,
  a:hover,
  a:active {
    text-decoration: none;
  }
  .logo-desktop {
    display: none;
  }
  .nav-main {
    .nav-logo-burger {
      background-color: ${Colors.primary.white.standard};
      border-bottom: solid 1px ${Colors.primary.black.tint};
      display: flex;
      flex-wrap: wrap;
      height: 55px;
      justify-content: space-between;
      position: relative;
      z-index: ${zIndicies.navLogoBurgerMobile};

      .logo-mobile {
        margin-left: -14px;
      }

      .logo {
        display: flex;
        flex-wrap: wrap;
        height: 25px;
        justify-content: space-between;
        margin: 15px 0 0 0px;

        img {
          margin-right: 12px;
          margin-top: -4px;
        }
        .page-title {
          border-left: solid 1px ${Colors.accent.grey4.standard};
          height: 28px;
          padding: 2px 0 0 12px;

          a {
            color: ${Colors.primary.black.standard};
            font: 18px source-sans-pro, sans-serif;

            &:hover {
              color: ${Colors.primary.claytonBlue.standard};
            }
          }
        }
      }
      .nav-burger {
        background-color: transparent;
        border: none;
        cursor: pointer;
        margin: 8px 12px 0 0;

        svg {
          height: 27px;
          width: 27px;
        }
      }
    }
    .nav-menu-items {
      background-color: ${Colors.primary.white.standard};
      box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.1);
      margin: 0 auto;
      padding: 25px 0;
      position: absolute;
      transition: margin 600ms ease;
      width: 100%;
      z-index: ${zIndicies.navMenuItemsMobile};
      
      .nav-menu-items-inside {
        margin: 0 auto;
        width: ${mobile_width_percentage}%;
      }
      &.closed {
        /* This has to account for the size of the mobile menu and max size of the WebsiteBanner component so the closed mobile menu is not visible on the banners */
        margin-top: -625px;
      }
      ul {
        list-style-type: none;

        li {
          margin-bottom: 25px;
          text-decoration: none;
          transition: background-color 450ms ease;
          -webkit-tap-highlight-color: transparent;
          -webkit-user-select: none;
          user-select: none;

          a {
            display: block;
            color: ${Colors.primary.black.standard};
            -webkit-tap-highlight-color: transparent;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            user-select: none;
            font-weight: 600;
          }

          &:hover {
              color: ${Colors.primary.claytonBlue.standard};
              background-color: ${Colors.primary.claytonBlue.tint};
          }

        }
      }
    }
  }
  @media screen and (min-width: ${desktop_breakpoint}px) {
    .nav-container-newsroom {
      margin: 0 auto;
      max-width: 1400px;
      position: relative;
      .nav-main {
        .nav-logo-burger .logo {
        margin: 15px 0px 0px 17px;
      }
    }
    .logo-desktop {
      padding-right: 15px;
      display: block;
      svg {
        width: 75px;
      }
    }
    .logo-mobile,
    .nav-burger {
      display: none;
    }
    .nav-main {
      .nav-logo-burger {
          border-bottom: none;

          .logo-desktop {
            margin: -5px 0 0 0;

            .page-title {
              margin-top: 7px;
            }
          }
        }
      .nav-menu-items {
        background-color: transparent;
        box-shadow: none;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        padding: 0;
        position: absolute;
        right: 0;
        top: 6px;
        width: 70%;
        z-index: ${zIndicies.navMenuItemsDesktop};
        
        .nav-menu-items-inside {
          margin: 0;
          width: 100%;
          ul {
            margin-right: 0;
          }
        }
        &.open, 
        &.closed {
          margin-top: 0;
        }
        ul {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-end;
          margin-right: ${openPositionsButtonWidth}px;
          margin-top: 8px;

          li {
            margin: 0 40px 0 0;

            a {
              color: ${Colors.accent.grey1.standard};
              font: 16px source-sans-pro, sans-serif;
              font-weight: normal;
            }
          }
        }
        .open-positions-link {
          position: absolute;
          top: 3px;
          right: 10px;

          button {
            width: ${openPositionsButtonWidth}px;
          }
        }
      }
    }
  }
`;
