import { createGlobalStyle } from 'styled-components';

// The brochure had its own global style that was originally in the Brochure.styled.js file. Copy additional style items as needed from base.js.
const GlobalStyle = createGlobalStyle`
/* put styles that are sitewide here.
they should be few and far between. */
   * {
      margin: 0;
      font-family: Arial, Helvetica, sans-serif;
      background: #fff !important;

      /* force print to show background image */
      -webkit-print-color-adjust: exact !important;   /* Chrome, Safari */
      color-adjust: exact !important;                 /*Firefox*/
   }

   @media print {
      html, body {
         page-break-after: avoid;
         page-break-before: avoid;
      }
   }

   /* hide the standard right-side Live Person button */
   .LPMcontainer {
      display: none !important;
   }

   /* Osano styles (e.g. hide cookie widget) */
   .osano-cm-widget {
      display: none;
   }
   .osano-cm-dialog {
      display: none;
   }

`;

export default GlobalStyle;
