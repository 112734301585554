import React, { useEffect } from 'react';
import Colors from 'pubweb-smokey/dist/colors';
import PropTypes from 'prop-types';
import ButtonStyles, {
  ExternalLinkButtonStyles,
  InternalLinkButtonStyles,
} from './Button.styled';

const Button = ({
  text,
  type,
  navigateUrl,
  nextJSUrl,
  forceExternalLink = false,
  target,
  isCompact = false,
  fillContainer,
  icon,
  primaryColor = Colors.primary.claytonBlue,
  buttonStyle = '',
  onDarkBg = false,
  disabled,
  onButtonClick,
  buttonID,
  automationId,
  className = '',
  linkComponent,
  ...props
}) => {
  const inverseStyles = ['shadow', 'outlined'];
  const propertyClasses = [];

  useEffect(() => {
    if (!automationId) {
      console.warn('Automation id was not provided.');
    }
  }, [automationId]);

  propertyClasses.push(primaryColor.className);
  propertyClasses.push(buttonStyle);

  if (inverseStyles.indexOf(buttonStyle) > -1) {
    propertyClasses.push('inverse');
  }

  if (isCompact) {
    propertyClasses.push('compact');
  }

  if (onDarkBg) {
    propertyClasses.push('on-dark');
  }

  if (disabled) {
    propertyClasses.push('disabled');
  }

  if (fillContainer) {
    propertyClasses.push('fill-container');
  }

  if (navigateUrl || nextJSUrl) {
    if (
      navigateUrl?.indexOf('http://') === 0 ||
      navigateUrl?.indexOf('https://') === 0 ||
      target == '_blank' ||
      forceExternalLink
    ) {
      return (
        <ExternalLinkButtonStyles
          className={className + ' ' + propertyClasses.join(' ')}
          href={navigateUrl || nextJSUrl}
          target={navigateUrl ? target || '_blank' : ''}
          rel="noreferrer noopener"
          id={buttonID}
          data-test-id={automationId}
          onClick={onButtonClick}
          type={type}
        >
          {icon ? (
            <>
              <div className="icon">{icon}</div>
              {text || props.children}
            </>
          ) : (
            text || props.children
          )}
        </ExternalLinkButtonStyles>
      );
    } else {
      return nextJSUrl ? (
        React.createElement(
          linkComponent,
          {
            href: nextJSUrl,
            passHref: true,
            legacyBehavior: true,
          },
          <InternalLinkButtonStyles
            className={className + ' ' + propertyClasses.join(' ')}
            rel="noreferrer noopener"
            id={buttonID}
            data-test-id={automationId}
            onClick={onButtonClick}
            type={type}
            {...props}
          >
            {icon ? (
              <>
                <div className="icon">{icon}</div>
                {text || props.children}
              </>
            ) : (
              text || props.children
            )}
          </InternalLinkButtonStyles>
        )
      ) : (
        <InternalLinkButtonStyles
          as={linkComponent}
          className={className + ' ' + propertyClasses.join(' ')}
          rel="noreferrer noopener"
          id={buttonID}
          data-test-id={automationId}
          onClick={onButtonClick}
          to={navigateUrl}
          type={type}
          {...props}
        >
          {icon ? (
            <>
              <div className="icon">{icon}</div>
              {text || props.children}
            </>
          ) : (
            text || props.children
          )}
        </InternalLinkButtonStyles>
      );
    }
  } else {
    return (
      <ButtonStyles
        className={className + ' ' + propertyClasses.join(' ')}
        id={buttonID}
        data-test-id={automationId}
        onClick={onButtonClick}
        type={type}
        {...props}
      >
        {icon ? (
          <>
            <div className="icon">{icon}</div>
            {text || props.children}
          </>
        ) : (
          text || props.children
        )}
      </ButtonStyles>
    );
  }
};

export default Button;

Button.propTypes = {
  buttonStyle: PropTypes.oneOf(['', 'solid', 'shadow', 'outlined']),
  target: PropTypes.string,
  isCompact: PropTypes.bool,
  text: PropTypes.string,
  fillContainer: PropTypes.bool,
  icon: PropTypes.object,
  primaryColor: PropTypes.oneOf([
    Colors.primary.claytonBlue,
    Colors.secondary.kiddiePool,
    Colors.secondary.summerNight,
    Colors.accent.ladyBug,
    Colors.accent.cheesePuff,
    Colors.secondary.rubberDucky,
    Colors.accent.darkBlue,
    Colors.primary.white,
    Colors.accent.claytonGreen,
    Colors.primary.black,
  ]),
  disabled: PropTypes.bool,
  onButtonClick: PropTypes.any,
  buttonID: PropTypes.string,
  automationId: PropTypes.string,
  linkComponent: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  navigateUrl: PropTypes.string,
  nextJSUrl: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.any,
  forceExternalLink: PropTypes.bool,
  onDarkBg: PropTypes.bool,
};

Button.defaultProps = {
  buttonStyle: '',
  isCompact: false,
  target: '',
  fillContainer: false,
  primaryColor: Colors.primary.claytonBlue,
  disabled: false,
};
