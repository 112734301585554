import PropTypes from 'prop-types';
import React, { useState, useEffect, createContext } from 'react';
import throttle from 'lodash.throttle';

export const PageContext = createContext({
  state: {
    footerInView: false,
    scrollDirection: 'up',
    livePersonVisible: false,
    mobileNavBarVisible: false,
  },
  actions: {
    setFooterInView: () => {},
    setLivePersonVisible: () => {},
    setMobileNavBarVisible: () => {},
  },
});

const PageContextProvider = ({ children }) => {
  const [footerInView, setFooterInView] = useState(false);
  const [scrollDirection, setScrollDirection] = useState('up');
  const [prevOffset, setPrevOffset] = useState(0);
  const [livePersonVisible, setLivePersonVisible] = useState(false);
  const [mobileNavBarVisible, setMobileNavBarVisible] = useState(false);

  const toggleScrollDirection = () => {
    const { scrollY } = window;
    const scrollBuffer = 16;

    if (scrollY > scrollBuffer && scrollY > prevOffset) {
      setScrollDirection('down');
    } else if (scrollY < prevOffset) {
      setScrollDirection('up');
    }

    setPrevOffset(scrollY);
  };

  useEffect(() => {
    const throttledScrollHandler = throttle(toggleScrollDirection, 100);

    window.addEventListener('scroll', throttledScrollHandler);

    return () => {
      window.removeEventListener('scroll', throttledScrollHandler);
    };
  });

  const currentContext = {
    state: {
      footerInView,
      scrollDirection,
      livePersonVisible,
      mobileNavBarVisible,
    },
    actions: {
      setFooterInView,
      setLivePersonVisible,
      setMobileNavBarVisible,
    },
  };

  return (
    <PageContext.Provider value={currentContext}>
      {children}
    </PageContext.Provider>
  );
};

PageContextProvider.propTypes = {
  children: PropTypes.any,
};

export default PageContextProvider;
