import styled from 'styled-components';

export const LivePersonMessageStyles = styled.button`
  padding: 0px;
  border-style: solid;
  border-width: 0px;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  list-style: outside none none;
  letter-spacing: normal;
  line-height: normal;
  text-decoration: none;
  vertical-align: baseline;
  white-space: normal;
  word-spacing: normal;
  background-repeat: repeat-x;
  background-position: left bottom;
  background-color: rgb(0, 117, 201);
  border-color: transparent;
  border-radius: 30px;
  // width: 140px;
  height: 40px;
  cursor: pointer;
  display: flex;
  z-index: 107158;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  inset: 50% 0px auto auto;
  // border: 1px solid red;

  img,
  svg {
    margin-left: 8px;
    margin-right: 8px;
    padding: 0px;
    border-style: none;
    border-width: 0px;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    list-style: outside none none;
    letter-spacing: normal;
    line-height: normal;
    text-decoration: none;
    vertical-align: baseline;
    white-space: normal;
    word-spacing: normal;
    z-index: 600;
    height: 18px;
    width: 18px;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }

  div {
    padding: 0px;
    border-style: none;
    border-width: 0px;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    list-style: outside none none;
    letter-spacing: normal;
    line-height: normal;
    text-decoration: none;
    vertical-align: baseline;
    white-space: nowrap;
    word-spacing: normal;
    font-family: Arial, Helvetica, sans-serif;
    color: rgb(255, 255, 255);
    font-size: 16px;
    z-index: 601;
  }

  .button-text {
    letter-spacing: -0.3px;
    padding-right: 9px;
  }
`;
