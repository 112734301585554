import styled from 'styled-components';
import { desktop_breakpoint } from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';
import Colors from 'pubweb-smokey/dist/colors';

export const UtilityNavStyles = styled.div`
  display: block;

  .utility-nav,
  .account-status-mobile {
    margin: 0;
    padding-left: 4px;
    li {
      margin-bottom: 15px;
      line-height: 0.98;
    }
  }
  .utility-nav {
    list-style-type: none;
    margin: 18px 0 26px 0;
    padding: 0 0 0 4px;
    li {
      line-height: 0.98;
    }
  }

  @media only screen and (min-width: ${desktop_breakpoint}px) {
    .account-status-mobile {
      display: none;
    }
    .utility-nav {
      display: flex;
      margin: 0;
      padding-top: 0;

      li {
        margin: 3px 28px 0 0;
        height: 30px;

        a {
          padding: 2px 5px;
          transition: background-color 450ms ease;

          &:hover {
            background-color: ${Colors.primary.claytonBlue.tint};
          }
        }
      }
    }
    .account-status-mobile,
    .utility-nav {
      margin: 0;
      padding: 0;
      li {
        line-height: 1.69;
      }
      .caption {
        font-size: 10px;
        font-weight: bold;
        letter-spacing: 0.5px;
        text-transform: uppercase;
      }
    }
  }

  @media screen and (max-width: ${desktop_breakpoint - 1}px) {
    .utility-nav {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      li:first-child {
        padding-top: 20px;
      }
    }
  }
`;
