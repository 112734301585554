import styled from 'styled-components';
import Colors from 'pubweb-smokey/dist/colors';

import * as variables from 'pubweb-smokey/dist/components/GridSystem/_vars_widths';

const colorOptions = [
  Colors.primary.claytonBlue,
  Colors.secondary.kiddiePool,
  Colors.secondary.summerNight,
  Colors.accent.ladyBug,
  Colors.accent.cheesePuff,
  Colors.secondary.rubberDucky,
  Colors.primary.white,
];

const TypographyWrapper = styled.div`
  .h1,
  h1,
  .h2,
  h2,
  .h3,
  h3,
  .h4,
  h4,
  .h5,
  h5,
  .h6,
  h6 {
    font-family: 'acumin-pro', sans-serif;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    color: ${Colors.primary.black.standard};

    ${colorOptions
      .map(
        (color) =>
          `&.${color.className} {
          color: ${color.standard};
      }`
      )
      .join('\n')}
  }

  .large-heading {
    font-size: 60px;
    line-height: 68px;
    letter-spacing: -0.525px;

    @media screen and (max-width: ${variables.desktop_breakpoint}px) {
      font-size: 40px;
      line-height: 48px;
      letter-spacing: -0.35px;
    }
  }

  .h1,
  h1 {
    font-size: 40px;
    line-height: 1.2;
    letter-spacing: -0.35px;

    @media screen and (max-width: ${variables.desktop_breakpoint}px) {
      font-size: 36px;
      line-height: 1.18;
      letter-spacing: -0.3px;
    }
  }

  .h2,
  h2 {
    font-size: 32px;
    line-height: 1.19;
    letter-spacing: -0.35px;

    @media screen and (max-width: ${variables.desktop_breakpoint}px) {
      font-size: 26px;
      line-height: 1.19;
      letter-spacing: -0.35px;
    }
  }

  .h3,
  h3 {
    font-size: 25px;
    line-height: 1.2;
    letter-spacing: -0.35px;

    @media screen and (max-width: ${variables.desktop_breakpoint}px) {
      font-size: 22px;
      line-height: 1.18;
      letter-spacing: -0.35px;
    }
  }

  .h4,
  h4 {
    font-size: 20px;
    line-height: 1.2;
    letter-spacing: -0.3px;

    @media screen and (max-width: ${variables.desktop_breakpoint}px) {
      font-size: 18px;
      line-height: 1.22;
      letter-spacing: -0.3px;
    }
  }

  .h5,
  h5 {
    font-size: 16px;
    line-height: 1.25;
    letter-spacing: -0.3px;
  }

  .h6,
  h6 {
    font-size: 14px;
    line-height: 1.25;
    letter-spacing: -0.3px;
  }
  p,
  .body-text,
  .standard,
  label,
  li {
    font-family: 'source-sans-pro', sans-serif;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.69;
    letter-spacing: normal;
    color: ${Colors.accent.grey1.standard};

    &.intro {
      font-size: 18px;
      line-height: 1.61;
      color: ${Colors.primary.black.standard};
    }

    ${colorOptions
      .map(
        (color) =>
          `&.${color.className} {
          color: ${color.standard};
      }`
      )
      .join('\n')}
  }

  .caption {
    font-family: 'source-sans-pro', sans-serif;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: ${Colors.accent.grey1.standard};

    &.short {
      line-height: 1.14;
    }

    ${colorOptions
      .map(
        (color) =>
          `&.${color.className} {
          color: ${color.standard};
      }`
      )
      .join('\n')}
  }
  .label {
    font-family: 'source-sans-pro', sans-serif;
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 0.5px;
    text-transform: uppercase;

    &.large {
      font-size: 12px;
    }
  }
  .xsmall {
    font-family: 'source-sans-pro', sans-serif;
    font-size: 12px;
    font-weight: normal;
    line-height: 1.42;
    letter-spacing: normal;
    color: ${Colors.accent.grey1.standard};

    ${colorOptions
      .map(
        (color) =>
          `&.${color.className} {
          color: ${color.standard};
      }`
      )
      .join('\n')}
  }
`;

export default TypographyWrapper;
