import zIndices, { setAbove } from 'pubweb-smokey/dist/components/zIndex';

zIndices.baseLine = setAbove(1);
zIndices.loadingIndicator = setAbove();
zIndices.sliderControls = setAbove();
zIndices.livePerson = setAbove();
zIndices.progressIndicator = setAbove();
zIndices.footer = setAbove();
zIndices.scrollToTopButton = setAbove();
zIndices.filterBars = setAbove();
zIndices.dropDownMenu = setAbove();
zIndices.dropDownMenuModal = setAbove();
zIndices.mobileDropDownMenu = setAbove();
zIndices.navMenuItemsMobile = setAbove();
zIndices.header = setAbove();
zIndices.navMenuItemsDesktop = setAbove();
zIndices.modal = setAbove();
zIndices.anchorModal = setAbove();
zIndices.anchorModalContent = setAbove();

const chZindex = { ...zIndices };

export default chZindex;
